import { Link } from "gatsby";
import React from "react";
import Icon from "./icon";
import { cn } from "../lib/helpers";
import Logo from "./logo"

import { motion, AnimatePresence } from "framer-motion";

import * as styles from "./header.module.css";

const navList = {
  visible: {
    width: "100vw",
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.15,
      bounce: 0,
      

    },
  },
  hidden: {
    width: "0vw",
    transition: {
      when: "afterChildren",
    },
  }
}

const navItem = {
  visible: {
    opacity: 1
  },
  hidden: {
    opacity: 0
  }
}

const Header = ({ onHideNav, onShowNav, showNav, siteTitle }) => (
  <motion.header className={styles.root} >
    <div className={styles.brandingAndMenu}>
    <div className={styles.branding}>
      <Link to="/">
        <img className={styles.logo} src="/topnotch_badge.png"></img>
      </Link>
    </div>
    <button className={styles.toggleNavButton} onClick={showNav ? onHideNav : onShowNav}>
      <p>Menu</p>
    </button>
    </div>
    <motion.nav 
      className={styles.nav}
    >
        <motion.ul 
          className={styles.navList}
          variants={navList}
          animate={showNav ? "visible" : "hidden"}
        >
          <motion.li variants={navItem}>
            <Link to="/services">Services</Link>
          </motion.li>
          <motion.li variants={navItem}>
            <Link to="/archive/">Work</Link>
          </motion.li>
          <motion.li variants={navItem}>
            <a href="mailto:hello@topnotchfilm.com">Contact</a>
          </motion.li>
        </motion.ul>
    </motion.nav>
  </motion.header>
);

export default Header;
