import { Link } from "gatsby";
import React from "react";
import Icon from "./icon";
import { cn } from "../lib/helpers";

import * as styles from "./footer.module.css";

const Footer = () => (
  <div className={styles.root}>
     <div className={styles.column}>
        <h3>About Us</h3>
        <ul className={styles.list}>
          <li><a href="mailto:hello@topnotchfilm.com">Contact</a></li>
        </ul>
    </div>

    <ul className={styles.copyright}>
      <li>© 2022 TopNotch Entertainment Corp. All Rights reserved.</li>

    </ul>
  </div>
);

export default Footer;
